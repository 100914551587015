import './App.css';
import { useAudio } from './AudioContext';
import { AudioProvider } from './AudioContext';

const TVScreen = ({ staticVariant, color }) => {
  return (
    <div className="tv-screen">
      <div className="tv-frame">
        <div className="screen-content">
          <div 
            className="static-effect"
            style={{
              backgroundImage: `url(${staticVariant})`
            }}
          />
          <div 
            className="color-overlay"
            style={{
              backgroundColor: color
            }}
          />
          <div className="screen-glare" />
        </div>
      </div>
    </div>
  );
};

function Screens() {
  const { playSound } = useAudio();

  

  return (
    <div className="App">
      <header className="App-header">
      <div className="screen-button-test"></div>
      <div className="screen-button-test"></div>
      <div className="screen-button-test"></div>
      <div className="screen-button-test"></div>
        <button className= "screen-button-GBeep"
          onClick={() => playSound('GBeep')}
        >
          Play GBeep
        </button>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <button className= "screen-button-ABeep"
          onClick={() => playSound('ABeep')}
        >
          Play ABeep
        </button>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <button className= "screen-button-CBeep"
          onClick={() => playSound('CBeep')}
        >
          Play CBeep
        </button>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>

        <button className= "screen-button-EBeep"
          onClick={() => playSound('EBeep')}
        >
          Play EBeep
        </button>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <button className= "screen-button-DBeep"
          onClick={() => playSound('DBeep')}
        >
          Play DBeep
        </button>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
        <div className="screen-button-test"></div>
      </header>
    </div>
  );
}

export default Screens;

import React, { useState, useRef, useEffect } from 'react';
import { useAudio } from './AudioContext';

const TVTest = () => {
  const [activeTV, setActiveTV] = useState(null);
  const [failureState, setFailureState] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [currentStep, setCurrentStep] = useState(3);
  const [isWaitingForPlayer, setIsWaitingForPlayer] = useState(true);
  const [currentSequence, setCurrentSequence] = useState(['GBeep', 'ABeep', ]);
  const videoRef = useRef(null);
  const [updater, setUpdater] = useState(0);
  const { playSound } = useAudio();
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [trackingStep, setTrackingStep] = useState(0);
  

  const WINNING_SEQUENCE = ['GBeep', 'ABeep', 'CBeep', 'ABeep', 'EBeep', 'EBeep', 'DBeep'];

  const playSound_and_Light = async (sound) => {
    const tv = tvs.find(t => t.sound === sound);
    if (tv) {
      setActiveTV(tv.id);
      playSound(sound);
      await new Promise(resolve => setTimeout(resolve, 500));
      setActiveTV(null);
    }
  };

  const playSound_and_LightFinale = async (sound) => {
    const tv = tvs.find(t => t.sound === sound);
    if (tv) {
      setActiveTV(tv.id);
      playSound(sound);
      await new Promise(resolve => setTimeout(resolve, 300));
      setActiveTV(null);
    }
  };


  const [tvs] = useState(() => {
    const soundTVs = [
      { color: 'red', sound: 'GBeep' },
      { color: 'blue', sound: 'ABeep' },
      { color: 'cyan', sound: 'CBeep' },
      { color: 'green', sound: 'EBeep' },
      { color: 'magenta', sound: 'DBeep' }
    ];

  
    const allTVs = Array(20).fill(null).map((_, index) => ({
      id: index,
      isInteractive: false,
      color: 'grey',
      sound: null,
      frame: `tv-frame_${Math.floor(Math.random() * 4) + 1}`, 
      static: `static-effect_${Math.floor(Math.random() * 5) + 1}`, 
      playsVideo: [4, 7, 8, 11, 12, 15, 18].includes(index)
    }));

 
    const positions = new Set();
    while(positions.size < 5) {
      positions.add(Math.floor(Math.random() * 20));
    }

  
    [...positions].forEach((pos, index) => {
      allTVs[pos] = {
        ...allTVs[pos],
        ...soundTVs[index],
        isInteractive: true
      };
    });

    return allTVs;
  });

  const handleTVClick = async (tv) => {
    console.log(trackingStep);
    console.log(currentStep);

    if(tv.sound !== WINNING_SEQUENCE[trackingStep]) {
     
      setFailureState(true);
      playSound('buzzer');
      setCurrentStep(3);  
      setTrackingStep(0);
      setIsWaitingForPlayer(true);
      setCurrentSequence(['GBeep', 'ABeep']); 
      setIsVideoPlaying(false);  
      setActiveTV(null); 

     
      setTimeout(() => {
        setFailureState(false);
        setUpdater(0);  
       
        setTimeout(() => setUpdater(1), 100);
      }, 100);

      return;
    }

    if (trackingStep === 6) {
      playNewSequenceFinale();
      return;
    }


    if (trackingStep + 3 === currentStep) {
        setActiveTV(tv.id);
        playSound(tv.sound);
        setTrackingStep(0);
        console.log("Playing new sequence");
        await setCurrentStep(currentStep + 1);
        if (tv.sound === "GBeep") {
            setIsWaitingForPlayer(false);
        }
        await new Promise(resolve => setTimeout(resolve, 500));
        playNewSequence();
        return;
    }
    setTrackingStep(trackingStep + 1);


    if (!WINNING_SEQUENCE.includes(tv.sound)) {
    
      console.log("Wrong TV clicked");
      setFailureState(true);
      playSound('buzzer');
      setCurrentStep(0); 
      setTimeout(() => {
        setFailureState(false);
      }, 2000);
      return;
    }

    
    setActiveTV(tv.id);
    playSound(tv.sound);
    
 


    setTimeout(() => setActiveTV(null), 500);
  };

  const handleVictory = () => {
    setIsVideoPlaying(true);
  };


  const demonstrateCurrentStep = async () => {
    if (!isWaitingForPlayer) {
        for (let i = 0; i < currentStep.length; i++) {
            await playSound_and_Light(WINNING_SEQUENCE[i]);
            await new Promise(resolve => setTimeout(resolve, 500));
        }
    }
    else {
        await playSound_and_Light(WINNING_SEQUENCE[0]);
    }
  };


  const playNewSequence = async () => {

    for (let i = 0; i < currentSequence.length; i++) {
        await playSound_and_Light(WINNING_SEQUENCE[i]);
        await new Promise(resolve => setTimeout(resolve, 100));
    }
    await setCurrentStep(currentStep + 1);
    setCurrentSequence(WINNING_SEQUENCE.slice(0, currentStep))
    console.log(currentStep);
    console.log(currentSequence);
  };


  const playNewSequenceFinale = async () => {
    for (let i = 0; i < currentSequence.length; i++) {
      await playSound_and_LightFinale(WINNING_SEQUENCE[i]);
      await new Promise(resolve => setTimeout(resolve, 0.05));
    }
    setIsVideoPlaying(true);
    

    const videos = document.querySelectorAll('.screen-video');
    videos.forEach(video => {
      video.play().catch(e => console.error('Video play failed:', e));
    });
  };

  const playIdleSequence = async (isActive) => {
    while(isActive) {
         try {
      await playSound_and_Light('GBeep');
      await new Promise(resolve => setTimeout(resolve, 2000)); 
    } catch (error) {
      console.error('Error in idle loop:', error);
    }
    }
  };



  const startGame = () => {
    setHasUserInteracted(true);
    setIsWaitingForPlayer(true);
  };

  const IdleSequence = async () => {
    if (isWaitingForPlayer) {
        await playSound_and_Light('GBeep');
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (updater > 0) {
        setUpdater(0);
      }
      else{
        setUpdater(1)
      }

    }
  };



  useEffect(() => {
    IdleSequence();
  }, [updater]);







  return (
    <div className="tv-wrapper">
      {!hasUserInteracted ? (
        <div className="start-screen">
          <button 
            className="start-button"
            onClick={startGame}
          >
            Click to Start
          </button>
        </div>
      ) : (
        <>
         
          <div className="tv-container">
            {tvs.map((tv) => (
              <div
                key={tv.id}
                className={`tv-screen 
                  ${activeTV === tv.id ? 'active' : ''} 
                  ${tv.isInteractive ? 'interactive' : ''} 
                  ${failureState ? 'failure' : ''}
                  ${isVideoPlaying ? 'video-playing' : ''}`}
                onClick={() => handleTVClick(tv)}
              >
                <div className={tv.frame}>
                  <div className="screen-content">
                    <div 
                      className={tv.static} 
                      style={{ 
                        opacity: isVideoPlaying && !tv.playsVideo ? 0 : 0.7 
                      }}
                    />
                    <div 
                      className="color-overlay"
                      style={{ 
                        backgroundColor: failureState ? 'red' : tv.color,
                        opacity: isVideoPlaying && !tv.playsVideo ? 0 : undefined 
                      }}
                    />
                    {tv.playsVideo && ( 
                      <video
                        className="screen-video"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          opacity: isVideoPlaying ? 1 : 0,
                          transition: 'opacity 0.3s'
                        }}
                        ref={tv.id === 8 ? videoRef : null}
                        muted={tv.id !== 8}
                        preload="none"
                        playsInline
                      >
                        <source src={process.env.PUBLIC_URL + '/videos/rickroll.mp4'} type="video/mp4" quality="low" />
                      </video>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
        </>
      )}
    </div>
  );
};

export default TVTest;
